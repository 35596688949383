<template>
  <div class="employee  employee--new">
    <div class="employee__title">
      <router-link :to="{ name: 'Employees' }" class="employee__title-link">
        <img src="@/assets/icons/icon-arrow.svg" alt="" />
      </router-link>
      <PageTitle text="Новый работник" />
    </div>
    <div class="employee__tabs">
      <el-tabs>
        <el-tab-pane label="Профиль">
          <NewEmployeeCard />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import NewEmployeeCard from '../components/blocks/NewEmployeeCard'

export default {
  name: 'NewEmployee',
  components: {
    PageTitle,
    NewEmployeeCard,
  },
  computed: {
    employeeData() {
      return this.$store.state.Employee.employeeData
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.employee {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }
  }
}

@media (max-width: $screen-xs-max){
	.employee--new{
		.el-tabs .el-tabs__active-bar::before{
			width: calc(100% - 30px);
			right: auto;
		}
		.employee__tabs{
			margin-left: -16px;
			margin-right: -16px;
		}
	}
	.employee__title-link{
		display: none;
	}
}
</style>
