var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-employee-card"
  }, [_c('el-form', {
    ref: "form",
    staticClass: "new-employee-card__form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm('form');
      }
    }
  }, [_c('div', {
    staticClass: "new-employee-card__data"
  }, [_c('div', {
    staticClass: "new-employee-card__info"
  }, [_c('div', {
    staticClass: "new-employee-card__row"
  }, [_c('div', {
    staticClass: "new-employee-card__col"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Полное имя",
      "prop": "name"
    }
  }, [_c('el-input', {
    ref: "name",
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Контактный телефон",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "E-mail",
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('div', {
    staticClass: "new-employee-card__col"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Пароль",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": "",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Повторите пароль",
      "prop": "checkPassword"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.checkPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "checkPassword", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.checkPassword"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Срок мед.страховки"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "picker-options": _vm.pickerOptions,
      "format": "dd.MM.yyyy",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.expiration_date_medical,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiration_date_medical", $$v);
      },
      expression: "form.expiration_date_medical"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Срок истечения договора"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "picker-options": _vm.pickerOptions,
      "format": "dd.MM.yyyy",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.expiration_date_contract,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiration_date_contract", $$v);
      },
      expression: "form.expiration_date_contract"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Разрешение на работу"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "picker-options": _vm.pickerOptions,
      "type": "date",
      "format": "dd.MM.yyyy",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.expiration_permission_to_work,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiration_permission_to_work", $$v);
      },
      expression: "form.expiration_permission_to_work"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "new-employee-card__photo"
  }, [_c('div', {
    staticClass: "new-employee-card__photo-title"
  }, [_vm._v("Фотография работника")]), _c('div', {
    staticClass: "new-employee-card__photo-item"
  }, [_vm.imageSrc && !_vm.alreadyUploaded ? _c('a', {
    staticClass: "new-employee-card__photo-clear",
    attrs: {
      "href": "#",
      "title": "Удалить"
    },
    on: {
      "click": _vm.clearUpload
    }
  }, [_c('span', {
    staticClass: "new-employee-card__photo-clear-icon"
  }, [_vm._v("x")])]) : _vm._e(), _c('label', {
    staticClass: "new-employee-card__photo-upload"
  }, [_c('input', {
    staticClass: "new-employee-card__photo-file",
    attrs: {
      "accept": "image/*",
      "type": "file"
    },
    on: {
      "change": _vm.previewThumbnail
    }
  }), _c('span', {
    staticClass: "new-employee-card__photo-figure"
  }, [_c('transition', {
    attrs: {
      "name": "el-fade-in"
    }
  }, [_vm.imageSrc ? _c('span', {
    staticClass: "new-employee-card__photo-figure-img",
    style: {
      backgroundImage: 'url(' + _vm.imageSrc + ')'
    }
  }) : _vm._e()])], 1), !_vm.imageSrc ? _c('span', {
    staticClass: "new-employee-card__photo-add"
  }, [_vm._v(" Добавить фото ")]) : _vm._e(), _vm.imageSrc ? _c('span', {
    staticClass: "new-employee-card__photo-edit"
  }, [_vm._v(" Изменить фото ")]) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "el-zoom-in-top"
    }
  }, [_vm.imageError ? _c('div', {
    staticClass: "new-employee-card__photo-error el-form-item__error",
    domProps: {
      "textContent": _vm._s(_vm.imageError)
    }
  }) : _vm._e()])], 1)])]), _c('div', {
    staticClass: "new-employee-card__buttons"
  }, [_c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": {
        name: 'Employees'
      }
    }
  }, [_vm._v(" Отменить ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "native-type": "submit",
      "disabled": _vm.isDisabled
    }
  }, [_vm._v(" Сохранить ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }