<template>
  <div class="new-employee-card">
    <el-form
      class="new-employee-card__form"
      ref="form"
      :model="form"
      :rules="rules"
      :hide-required-asterisk="true"
      @submit.native.prevent="submitForm('form')"
    >
      <div class="new-employee-card__data">
        <div class="new-employee-card__info">
          <div class="new-employee-card__row">
            <div class="new-employee-card__col">
              <el-form-item label="Полное имя" prop="name">
                <el-input v-model="form.name" ref="name" type="text" />
              </el-form-item>
              <el-form-item label="Контактный телефон" prop="phone">
                <el-input v-model.trim="form.phone" type="text" />
              </el-form-item>
              <el-form-item label="E-mail" prop="email">
                <el-input v-model.trim="form.email" type="text" />
              </el-form-item>
            </div>
            <div class="new-employee-card__col">
              <el-form-item label="Пароль" prop="password">
                <el-input
                  v-model.trim="form.password"
                  type="password"
                  show-password
                  autocomplete="new-password"
                />
              </el-form-item>
              <el-form-item label="Повторите пароль" prop="checkPassword">
                <el-input
                  v-model.trim="form.checkPassword"
                  type="password"
                  show-password
                />
              </el-form-item>
              <el-form-item label="Срок мед.страховки">
                <el-date-picker
                  v-model="form.expiration_date_medical"
                  type="date"
                  :picker-options="pickerOptions"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item label="Срок истечения договора">
                <el-date-picker
                  v-model="form.expiration_date_contract"
                  type="date"
                  :picker-options="pickerOptions"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item label="Разрешение на работу">
                <el-date-picker
                  :picker-options="pickerOptions"
                  v-model="form.expiration_permission_to_work"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="new-employee-card__photo">
          <div class="new-employee-card__photo-title">Фотография работника</div>
          <div class="new-employee-card__photo-item">
            <a
              href="#"
              v-if="imageSrc && !alreadyUploaded"
              class="new-employee-card__photo-clear"
              title="Удалить"
              @click="clearUpload"
            >
              <span class="new-employee-card__photo-clear-icon">x</span>
            </a>
            <label class="new-employee-card__photo-upload">
              <input
                class="new-employee-card__photo-file"
                accept="image/*"
                type="file"
                @change="previewThumbnail"
              />
              <span class="new-employee-card__photo-figure">
                <transition name="el-fade-in">
                  <span
                    v-if="imageSrc"
                    class="new-employee-card__photo-figure-img"
                    :style="{ backgroundImage: 'url(' + imageSrc + ')' }"
                  ></span>
                </transition>
              </span>
              <span v-if="!imageSrc" class="new-employee-card__photo-add">
                Добавить фото
              </span>
              <span v-if="imageSrc" class="new-employee-card__photo-edit">
                Изменить фото
              </span>
            </label>
            <transition name="el-zoom-in-top">
              <div
                v-if="imageError"
                class="new-employee-card__photo-error el-form-item__error"
                v-text="imageError"
              />
            </transition>
          </div>
        </div>
      </div>
      <div class="new-employee-card__buttons">
        <router-link :to="{ name: 'Employees' }" class="el-button">
          Отменить
        </router-link>
        <el-button type="primary" native-type="submit" :disabled="isDisabled">
          Сохранить
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "NewEmployeeCard",
  data() {
    // Проверка на бэке, не существует ли уже такой имэйл
    const checkEmail = (rule, value, callback) => {
      if (this.errors && this.errors.email) {
        callback(new Error(`${this.errors.email}`));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Это поле обязательно для заполнения"));
      } else {
        if (this.form.checkPassword !== "") {
          this.$refs.form.validateField("checkPassword");
        }
        callback();
      }
    };
    const validatePassword2 = (rule, value, callback) => {
      if (value === "" && this.form.password === "") {
        callback(new Error("Это поле обязательно для заполнения"));
      } else if (value === "") {
        callback(new Error("Введите пароль еще раз"));
      } else if (value !== this.form.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (this.errors && this.errors.password) {
        callback(new Error(`${this.errors.password}`));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      form: {
        name: "",
        phone: "",
        email: "",
        password: "",
        checkPassword: "",
        expiration_date_contract: "",
        expiration_date_medical: "",
        expiration_permission_to_work: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Это поле обязательно для заполнения",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          {
            required: true,
            message: "Это поле обязательно для заполнения",
            trigger: ["blur", "change"]
          },
          {
            pattern: /^[+]{1}[0-9]{7,20}$/,
            message: "Номер должен состоять из минимум 7 цифр и начинаться с +",
            trigger: ["blur", "change"]
          }
        ],
        email: [
          {
            required: true,
            message: "Это поле обязательно для заполнения",
            trigger: ["blur", "change"]
          },
          {
            type: "email",
            message: "Выбранное значение для E-Mail адреса некорректно",
            trigger: "blur"
          },
          { validator: checkEmail, trigger: "blur" }
        ],
        password: [
          { validator: validatePassword, trigger: "blur" },
          { validator: checkPassword, trigger: "blur" }
        ],
        checkPassword: [
          { validator: validatePassword2, trigger: ["blur", "change"] }
        ]
      },
      file: null,
      imageSrc: null,
      imageError: null,
      imageUploadSrc: null,
      alreadyUploaded: false,
      isDisabled: false
    };
  },
  computed: {
    errors() {
      return this.$store.state.Employee.createEmployeeErrors;
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.commit("Employee/setData", {
          label: "createEmployeeErrors",
          data: null
        });
      }
    }
  },
  mounted() {
    this.$refs.name.focus();
  },
  methods: {
    submitForm(formName) {
      this.isDisabled = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.file === null) {
            this.createEmployee();
          } else {
            this.createEmployeeWithPhoto();
          }
        } else {
          this.isDisabled = false;
          return false;
        }
      });
    },
    createEmployee() {
      const data = {
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.checkPassword,
        expiration_date_contract: this.form.expiration_date_contract,
        expiration_date_medical: this.form.expiration_date_medical,
        expiration_permission_to_work: this.form.expiration_permission_to_work,
        image: this.imageUploadSrc
      };
      this.$store
        .dispatch("Employee/createEmployee", data)
        .then(resp => {
          this.$notify({
            title: "Успех",
            message: "Создан новый работник",
            type: "success"
          });
          // Обнулить поле file, чтобы не загружать это изображение снова,
          // пока оно не будет изменено пользователем
          this.file = null;
          // Обнулить поле imageUploadSrc, чтобы не отправлять повторно url изображения,
          // пока оно не будет изменено пользователем
          this.imageUploadSrc = null;
          // Так как нет функционала по удалению аватарок у сотрудников,
          // нужно скрыть крестик удаления изображение,
          // если оно уже загружено на сервер и привязано к сотруднику
          this.alreadyUploaded = true;
          this.$router.push({
            name: "Employee",
            params: { id: resp.data.data.id }
          });
          this.isDisabled = false;
        })
        .catch(() => {
          this.$refs["form"].validate(() => {});
          this.$notify({
            title: "Ошибка",
            message:
              "Ошибка. Новый работник не добавлен. Проверте правильность заполнения полей формы",
            type: "error"
          });
          this.isDisabled = false;
        });
    },
    createEmployeeWithPhoto() {
      let formData = new FormData();
      formData.append("image", this.file);

      this.$store.dispatch("Employee/uploadImage", formData).then(resp => {
        this.imageUploadSrc = resp.data.data;
        this.createEmployee();
      });
    },
    previewThumbnail(event) {
      this.file = event.target.files[0];
      let input = event.target;

      if (input.files && input.files[0]) {
        this.imageError = null;
        let reader = new FileReader();
        const fileType = input.files[0].type;
        const fileSize = input.files[0].size;
        const maxSize = Math.pow(1024, 2); // 1 MB
        const $this = this;

        if (fileSize > maxSize) {
          this.imageError = "Файл должен быть меньше 1 MB";
          return false;
        }

        if (
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/png"
        ) {
          reader.onload = function(e) {
            $this.imageSrc = e.target.result;
          };

          reader.readAsDataURL(input.files[0]);
        } else {
          this.imageError = "Поддерживаются форматы .jpeg, .jpg, .png";
        }
      }
    },
    clearUpload() {
      this.file = null;
      this.imageSrc = null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.new-employee-card {
  &__form {
    display: block;
  }

  &__data {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__info {
    width: 70%;
    padding-right: 2.4rem;
  }

  &__row {
    margin: 0 -1.2rem -2.4rem;
    display: flex;
    align-items: flex-start;
  }

  &__col {
    width: 50%;
    padding: 0 1.2rem 2.4rem;
  }

  &__photo {
    width: 30%;
    max-width: 23.2rem;

    &-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $form-def-label-color;
      margin-bottom: 0.4rem;
    }

    &-item {
      position: relative;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-clear {
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      height: 4rem;
      width: 4rem;
      cursor: pointer;
      transition: opacity $transition-duration;
      font-size: 0;
      line-height: 0;

      &-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 2.4rem;
        height: 2.4rem;
        margin: -1.2rem;
        border-radius: 50%;
        border: 1px solid $primary;
        background-color: rgba(#fff, 0.1);
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.8521 4.86167C20.0492 5.05879 20.0492 5.37838 19.8521 5.5755L13.4277 11.9999L19.8522 18.4245C20.0493 18.6216 20.0493 18.9412 19.8522 19.1383L19.1384 19.8522C18.9413 20.0493 18.6217 20.0493 18.4246 19.8522L12 13.4276L5.5755 19.8521C5.37838 20.0492 5.05879 20.0492 4.86167 19.8521L4.14784 19.1383C3.95072 18.9412 3.95072 18.6216 4.14784 18.4244L10.5723 11.9999L4.14794 5.57556C3.95083 5.37844 3.95083 5.05884 4.14794 4.86173L4.86177 4.14789C5.05889 3.95078 5.37849 3.95078 5.5756 4.14789L12 10.5723L18.4244 4.14784C18.6216 3.95072 18.9412 3.95072 19.1383 4.14784L19.8521 4.86167Z' fill='%231199f0'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.2rem auto;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    &-upload {
      display: block;
      position: relative;
      cursor: pointer;
    }

    &-file {
      @include visibility-hide;
    }

    &-figure {
      display: block;
      position: relative;
      overflow: hidden;
      border: 1px solid #daebfb;
      background-color: #f2f9ff;
      background-image: url("data:image/svg+xml,%3Csvg width='88' height='88' viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='44' cy='44' r='43.5' stroke='%23BDD2E6'/%3E%3Cpath d='M67.6955 80.4093C64.879 82.3379 55.2912 87.5001 44 87.5001C38.2116 87.5001 32.5896 86.0518 28.2256 84.4152C26.0454 83.5977 24.1861 82.7357 22.7849 81.99C21.3754 81.2397 20.4774 80.632 20.1617 80.3213C20.1605 80.3172 20.132 80.2678 20.113 80.1255C20.0904 79.9563 20.0894 79.7162 20.1219 79.4049C20.1868 78.7846 20.3774 77.9478 20.7132 76.9628C21.3837 74.9959 22.6145 72.4925 24.4879 70.0306C28.2294 65.1139 34.5102 60.389 44 60.389C53.5217 60.389 59.4294 64.2869 62.968 68.755C66.402 73.0907 67.6141 77.9758 67.6955 80.4093ZM58.02 40.3334C58.02 42.1945 57.6571 44.0372 56.9521 45.7564C56.2471 47.4755 55.214 49.0373 53.9118 50.3525C52.6097 51.6678 51.0642 52.7109 49.3636 53.4224C47.663 54.1339 45.8404 54.5001 44 54.5001C42.1596 54.5001 40.337 54.1339 38.6364 53.4224C36.9358 52.7109 35.3902 51.6678 34.0881 50.3525C32.786 49.0373 31.7528 47.4755 31.0479 45.7564C30.3429 44.0372 29.98 42.1945 29.98 40.3334C29.98 36.5746 31.4583 32.9707 34.0881 30.3143C36.7178 27.6581 40.2833 26.1667 44 26.1667C47.7167 26.1667 51.2822 27.6581 53.9118 30.3143C56.5417 32.9707 58.02 36.5746 58.02 40.3334Z' stroke='%23BDD2E6'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 38.3% auto;
      border-radius: 4px;
      flex: 1 auto;

      &::before {
        content: "";
        display: block;

        @include ratio(232, 293);
      }

      &-img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    &-add {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-decoration: underline;
      color: $primary;

      &:hover {
        text-decoration: none;
      }
    }

    &-edit {
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-decoration: underline;
      color: $primary;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    line-height: 1.6rem;

    &-confirm {
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
    }

    &-cancel {
      color: $error;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__buttons {
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .new-employee-card__data {
    flex-direction: column;
  }
  .new-employee-card__photo {
    order: -15;
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
  }
  .new-employee-card__photo-title {
    margin-bottom: 16px;
  }
  .new-employee-card__photo-item {
    width: 116px;
  }
  .new-employee-card__info {
    width: 100%;
    padding-right: 0;
  }
  .new-employee-card__row {
    flex-direction: column;
    margin: 0;
  }
  .new-employee-card__col {
    width: 100%;
    padding: 0;

    + .new-employee-card__col {
      margin-top: 24px;
    }
  }
  .new-employee-card__add-speciality {
    width: 100%;
  }
  .new-employee-card__password {
    justify-content: flex-end;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .new-employee-card__buttons {
    flex-direction: column;
    align-items: center;

    .el-button {
      margin: 0;

      &.el-button--primary {
        width: 100%;
      }
    }
    .el-button:not(.el-button--primary) {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      order: 10;
      padding: 0;
      margin-top: 16px;
    }
  }
}
</style>
