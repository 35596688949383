var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee  employee--new"
  }, [_c('div', {
    staticClass: "employee__title"
  }, [_c('router-link', {
    staticClass: "employee__title-link",
    attrs: {
      "to": {
        name: 'Employees'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-arrow.svg"),
      "alt": ""
    }
  })]), _c('PageTitle', {
    attrs: {
      "text": "Новый работник"
    }
  })], 1), _c('div', {
    staticClass: "employee__tabs"
  }, [_c('el-tabs', [_c('el-tab-pane', {
    attrs: {
      "label": "Профиль"
    }
  }, [_c('NewEmployeeCard')], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }